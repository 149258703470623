<template>
  <div class="site-sidebar">
    <!-- 最左侧大菜单 -->
    <div class="menu">
      <div
        class="menu-item"
        v-for="(item, index) in menuList"
        :key="index"
        :class="ActiveId === item.id ? 'menu-item-active' : ''"
        @click="changeMenu(item)"
      >
        <div class="icon">
          <SvgIcon
            :iconname="ActiveId === item.id ? item.icon + '_select' : item.icon"
          />
        </div>
        <div class="title">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import SvgIcon from "../../components/svg-icon/index.vue";
import "../../assets/iconfont/iconfont.js";
export default {
  data() {
    return {
      menuActive: {},
    };
  },
  components: {
    SvgIcon,
  },
  computed: {
    ...mapState("common", {
      menuList: (state) => state.menuList,
      menuActiveName: (state) => state.menuActiveName,
      ActiveId: (state) => state.ActiveId,
    }),
  },
  methods: {
    ...mapMutations("common", [
      "updateMenuList",
      "updateMenuActiveName",
      "updataSecondMenuList",
      "updateMainTabsActiveName",
      "updataActiveId",
    ]),
    changeMenu(data) {
      let url = this.getInitUrl(data);
      //   if (!data.children && data.url) {
      //     url = data.url;
      //   } else if (
      //     data.children &&
      //     data.children.length > 0 &&
      //     data.children[0].url
      //   ) {
      //     url = data.children[0].url;
      //   } else if (
      //     data.children.children &&
      //     data.children[0].children.length > 0 &&
      //     data.children[0].children[0].url
      //   ) {
      //     url = data.children[0].children[0].url;
      //   }

      this.$router.push({ path: "/" + url });
      this.updataActiveId(data.id);
      this.updataSecondMenuList(data.children);
      this.updateMainTabsActiveName(url);
    },
    /**
     * 如果不存在返回空
     * 如果找到url则结束递归，返回结果
     * 否则继续递归查询
     */
    getInitUrl(data) {
      if (!data) {
        return "";
      }
      if (data.url) {
        return data.url;
      }
      return this.getInitUrl(data.children[0]);
    },
  },
};
</script>
<style lang="scss" scoped>
.icon {
  width: 1rem;
  height: 1rem;
  vertical-align: -0.15rem;
  fill: currentColor;
  overflow: hidden;
}
</style>
